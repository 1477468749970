(function ($) {
    let $fn = $(".comp_slider_rooms_description");
    cssLoaded(function() {
        if ($fn.length) {
            let $slider = $fn.find("[data-slider]"),
                $slider_caption = $fn.find("[data-slider-caption]"),
                $arrow_prev = $fn.find("[data-slider-arrow=\"prev\"]"),
                $arrow_next = $fn.find("[data-slider-arrow=\"next\"]"),
                $counter = $fn.find("[data-slider-counter]");

            if ($slider.children().length > 1){
                let $slider_options = {
                    infinite: true,
                    arrows: true,
                    prevArrow: $arrow_prev,
                    nextArrow: $arrow_next,
                    dots: false
                };
                $slider.slick($.extend({},$slider_options,{
                    asNavFor: $slider_caption,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "0px",
                    focusOnSelect: true
                }));
                $slider_caption.slick($.extend({},$slider_options,{
                    fade: true,
                    slidesToShow:1,
                    asNavFor: $slider,
                    adaptiveHeight: true
                }));

                $slider.on('afterChange', function(event, slick, currentSlide){
                    $counter.text(currentSlide+1);
                });
            }
            else {
                $fn.find(".wrp_comp_nav").remove();
            }
        }
    });
})(jQuery);
