(function ($) {
    let $fn = $(".comp_slider_offers");
    cssLoaded(function() {
        if ($fn.length) {
            let $slider = $fn.find("[data-slider]"),
                $prevArrow = $fn.find("[data-slider-arrow=\"prev\"]"),
                $nextArrow = $fn.find("[data-slider-arrow=\"next\"]");

            if($slider.children().length > 3){
                $slider.slick({
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true,
                    prevArrow: $prevArrow,
                    nextArrow: $nextArrow,
                    centerMode: true,
                    centerPadding: "10px",
                    focusOnSelect: true,
                    responsive: [
                        {
                            breakpoint: 960,
                            settings: {
                                centerMode: false,
                                slidesToShow: 2
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                centerMode: false
                            }
                        }
                    ]
                });
            }
        }
    });
})(jQuery);
