(function($) {
    var reInitGCaptcha;

    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6Lctr-EUAAAAAMGjF1fJsE5Phylj2p9mbj8_s5IS").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6Lctr-EUAAAAAMGjF1fJsE5Phylj2p9mbj8_s5IS', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
    }

    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    $(document).on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function(){
        $(document).find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    var picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !$("html").hasClass("ie"))
    {
        $.getScript($cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        $html.addClass("ie11");
    }
  
    // AJAX FORM
    $(document).on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        var $frm = $(this);
        var formData = new FormData($(this)[0]);
        $.ajax({
            method: $frm.attr('method'),
            url: $frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false
        });
    });
    
    //gallery
    $(document).on('click', '[data-changegallery]', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1}
        }).done(function() {
            $('[data-changegallery]').removeClass('mod--active');
            $el.addClass('mod--active');
            fn_render_gallery_slider($(".comp_slider_gallery"));
        });
    });
    

    // ALAX LINKS
    $(document).on('click', '[href].ajax', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1}
        });
    });

    var antispam = function () {
        if ($('#snippet-contactform').length) {
            setTimeout(function () {
                $('#snippet-contactform').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
            }, 3000);
        }
    };

    var antispamnewsletter = function () {
        if ($('#snippet-newsletter').length) {
            setTimeout(function () {
                $('#snippet-newsletter').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
            }, 2000);
        }
    };
    
    $(document).ready(function () {
        antispam();
        antispamnewsletter();
    });
    
    

    /* GLOBAL AJAX EVENT HANDLER */
    $.ajaxSetup({
        dataType: "json",
        success: function (payload) {
            if (payload) {
                // přesměrování "this" přesměruje na aktuální stránku
                if (payload.redirect) {
                    if (payload.redirect == 'this') {
                        location.href = window.location.href.split("#")[0];
                    } else {
                        location.href = payload.redirect;
                    }
                }

                // výměna snippetů v šabloně - každý snippet musí mít unikátní ID
                if (payload.snippets) {
                    for (var i in payload.snippets) {
                        $("#" + i).replaceWith(payload.snippets[i]);
                    }
                }

                // výměna adresy v adresním řádku
                if (payload.replaceState) {
                    window.history.replaceState(null, payload.replaceState.title || null, payload.replaceState.url);

                    if (payload.replaceState.title) {
                        document.title = payload.replaceState.title;
                    }

                    if (typeof window.ga === "function") {
                        ga('send', 'pageview', window.location.pathname + window.location.search);
                    }
                }
                antispam();
                antispamnewsletter();
            }
        }
    });

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.getScript($cdnjs.lui_cookieconsent);
        }
    })();


    (function fn_exitBanner() {
        if ($(".part_dialog_exit_banner").length) {
            $.getScript($cdnjs.lui_exitbanner);
        }
    })();

})(jQuery);